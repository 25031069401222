import { createReducer, on } from '@ngrx/store';
import { resizeAction } from './layout.action';

export interface ILayout {
  leftWidth: number;
  rightWidth: number;
}

export const initialState: ILayout = {
  leftWidth: 30,
  rightWidth: 70
}

export const layoutReducer = createReducer(
  initialState,
  on(resizeAction, (state, { layoutData }) => {
    return {
      leftWidth: layoutData.leftWidth,
      rightWidth: layoutData.rightWidth
    }
  })
)