import { createReducer, on } from "@ngrx/store";
import { MonitoringState } from "./monitoring-state.interface";
import * as MonitoringActions from './monitoring.action';
import { DateTime } from "luxon";

const initialState: MonitoringState = {
    pvData: [],
    month: DateTime.now(),
    error: null
};

export const monitoringReducer = createReducer(
    initialState,
    on(MonitoringActions.pvSuccessAction, (state, {pvData}) => {
        const checkArray = pvData ?? [];
        return {
            ...state,
            pvData: [...checkArray ],
            error: null
        }
    }),
    on(MonitoringActions.pvFailureAction, (state, {error}) => {
        return {
            ...state,
            error
        }
    }),
    on(MonitoringActions.monthUpdteAction, (state, {month}) => {
        return {
            ...state,
            month
        }
    })
)

