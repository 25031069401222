import { createReducer, on } from "@ngrx/store";
import { triggerAction } from "./trigger.action";

export interface ITrigger {
  type: string;
}
export const initialState: ITrigger = {
  type: 'none'
}

export const triggerReducer = createReducer(
  initialState,
  on(triggerAction, (state, {trigger}) => {
    return {type: trigger.type}
  }),
);