import { createReducer, on } from "@ngrx/store";
import { AuthState } from "./auth-state.interface";
import * as AuthActions from './auth.actions';

const initialState: AuthState = {
    accessToken: null,
    user: null,
    error: undefined
};

export const authReducer = createReducer(
    initialState,
    // on(AuthActions.loginSuccessAction, (state, {}))
)