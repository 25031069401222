import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreModule } from '../../Modules/core/core.module';
import { AuthEffects } from '../../Modules/core/store/auth/auth.effects';
import { authReducer } from '../../Modules/core/store/auth/auth.reducers';
import { categoryReducer } from '../../Modules/core/store/category/category.reducer';
import { deliverReducer } from '../../Modules/core/store/deliver/deliver.reducer';
import { expansionPanelBoxReducer } from '../../Modules/core/store/expansion-panel-box/expansion-panel-box.reducer';
import { layoutReducer } from '../../Modules/core/store/layout/layout.reducer';
import { nodeReducer } from '../../Modules/core/store/node/node.reducer';
import { triggerReducer } from '../../Modules/core/store/trigger/trigger.reducer';
import { vectordbReducer } from '../../Modules/core/store/vectordb/vectordb.reducer';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../../Modules/core/interceptors/auth.interceptor';
import { monitoringReducer } from '../../Modules/core/store/monitoring/monitoring.reducer';
import { MonitoringEffects } from '../../Modules/core/store/monitoring/monitoring.effect';

export function tokenGetter(){
  return localStorage.getItem('accessToken');
}
import { SharedModule } from '../../Modules/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SvgBackground } from './svg/background.svg';

@NgModule({
  declarations: [
    AppComponent,
    SvgBackground
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot({
      expansionPanelBox: expansionPanelBoxReducer,
      trigger: triggerReducer,
      deliver: deliverReducer,
      auth: authReducer,
      category: categoryReducer,
      layout: layoutReducer,
      node: nodeReducer,
      vectordb: vectordbReducer,
      monitoring: monitoringReducer,
    }),
    EffectsModule.forRoot([
      AuthEffects,
      MonitoringEffects
    ]),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    })
  ],
  providers: [
    provideAnimations(), 
    provideAnimationsAsync(),
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
