import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
    // login$ = createEffect(() =>
    //     this.actions.pipe(
    //         ofType(AuthActions.loginAction),
    //         mergeMap((action) => 

    //         )
    //     )
    // )

    constructor(
        private actions: Actions,
        private store: Store,

    ){}
}