import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('인터셉터 호출', req.url);
        
        const accessToken = localStorage.getItem('accessToken');

        if(accessToken){
            const cloneRequest = req.clone({
                headers: req.headers.set('Authorization',
                    "Bearer " + accessToken
                )
            })

            return next.handle(cloneRequest);
        }

        return next.handle(req);
    }

}