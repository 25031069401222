import { createReducer, on } from "@ngrx/store";
import { currentCategoryAction } from "./category.action";

export interface ICategoryManage {
  currentCategoryId: number;
}

export const initialState: ICategoryManage = {
  currentCategoryId: -1
}

export const categoryReducer = createReducer(
  initialState,
  on(currentCategoryAction, (_state, {currentCategoryId}) => {
    console.log('lalala');
    //console.log('ngrx freeze', Object.isFrozen(currentCategoryId));
    return {currentCategoryId: currentCategoryId}
  }),
);