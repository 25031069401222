import { createReducer, on } from "@ngrx/store";
import { deliverAction } from "./deliver.action";

export interface IDeliver {
  type: string;
  data: any;
}

export const initialState: IDeliver = {
  type: '',
  data: ''
}

export const deliverReducer = createReducer(
  initialState,
  on(deliverAction, (_state, {deliver}) => {
    console.log('ngrx freeze', Object.isFrozen(deliver));
    return {...deliver}
  }),
);