import { createReducer, on } from "@ngrx/store";
import { addExpansionPanelBox, sortExpansionPanelBox } from "./expansion-panel-box.action";

export interface IExpansionPanelBoxData {
  index: number;
  question: string;
  documentTitle: string;
  documentContent: string;
  documentPage: string;
}

export interface IExpansionPanelBox {
  components: IExpansionPanelBoxData[];
  isSorted: boolean;
}

export const initialState: IExpansionPanelBox = {
  components: [],
  isSorted: true
}

export const expansionPanelBoxReducer = createReducer(
  initialState,
  on(addExpansionPanelBox, (state, { expansionPanelBox }) => {
    return {
      ...state,
      components: state.isSorted 
      ? [...state.components, ...expansionPanelBox]
      : [...expansionPanelBox, ...state.components],
  
    }
  }),
  on(sortExpansionPanelBox, state => {
    const updateIsSorted = !state.isSorted;
    return {
      ...state,
      isSorted: updateIsSorted,
      components: [...state.components].sort((a, b) => {
        return updateIsSorted ? b.index - a.index : a.index - b.index;
      })
    };
  })
);