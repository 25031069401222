import { createReducer, on } from "@ngrx/store";
import { selectVectorDB, unselectVectorDB } from "./vectordb.action";

// export interface IVectorDbState {
//   vectorDBs: ReadonlyArray<number>;
// }

export const initialState: ReadonlyArray<number> = [];

export const vectordbReducer = createReducer(
  initialState,
  on(selectVectorDB, (_state, {name}) => {//_state는 건들이면 안됨!
    if(_state.indexOf(name) > -1) return _state;

    return [..._state, name];
  }),
  on(unselectVectorDB, (_state, {name}) => {    
    return _state.filter(data => data !== name )
  }),
);