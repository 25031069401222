import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../../src/environments/environment';

const config: SocketIoConfig = { url: `${environment.socket_url}/stream`, options: { autoConnect : false } }

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
  ]
})
export class CoreModule { }
