import { createReducer, on } from '@ngrx/store';
import { INode } from '../../../pages/chatflow/interfaces/chatflow.interface';
import { addNode, clearNode, updateNode } from './node.action';

export const initialState: INode = {
  id: "",
  type: "",
  node: {
    category: "",
    result: [],
    isAwait: false
  },
  index: -1,
  left: false,
  right: false,
}

export const nodeReducer = createReducer(
  initialState,
  on(addNode, (state, { data }) => {
    return {
      id: data.id,
      type: data.type,
      node: {
        category: data.node.category,
        result: data.node.result,
        isAwait: data.node.isAwait
      },
      index: data.index,
      left: data.left,
      right: data.right
    }
  }),
  on(updateNode, (state, { data }) => ({
    ...state,
    node: {
      category: data.node.category,
      result: data.node.result,
      isAwait: data.node.isAwait
    },
    index: data.index,
    left: data.left,
    right: data.right
  })),
  on(clearNode, state => ({
    ...initialState
  }))
);