import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
export interface IAnimateAttribute {
  attributeName?: string;
  values?: string;
  keyTimes?: string;
  dur?: string;
  repeatCount?: string | number;
  begin?: string | number;
  offset?: string;
  stopColor?: string;
  type?: string;
  from?: string;
  to?: string;
}

export enum DrawType {
  rect = 'rect',
  ellipse = 'ellipse',
  image = 'image',
  circle = 'circle',
  text = 'text',
  path = 'path',
  line = 'line',
  foreignObject = 'foreignObject',
  div = 'xhtml:div',
}

@Component({
  selector: 'svg-background',
  template:
    '<svg #canvas viewBox="0 0 100 100"></svg><ng-container #container></ng-container>',
})
export class SvgBackground implements OnInit {
  protected d3 = d3;
  protected componentSVG!: any;
  @ViewChild('canvas', { static: true })
  public canvasElement!: ElementRef<any>;

  constructor() {}

  ngOnInit(): void {
    this.baseInit();
    this.createLayout();
   // this.changeColorsToWhite();
  }

  public createStop(node: any, value: IAnimateAttribute) {
    const item = node
      .append('stop')
      .attr('offset', value.offset)
      .attr('stop-color', value.stopColor);
    return item;
  }

  protected baseInit(isCursor = false) {
    this.componentSVG = this.d3.select(this.canvasElement.nativeElement);
  }

  private gradientColorsDark = {
    backGradient1: {
      color1: 'rgba(40, 218, 243, 1)',
      color2: 'rgba(40, 218, 243, 0)',
    },
    backGradient2: {
      color1: 'rgba(110, 42, 243, 1)',
      color2: 'rgba(110, 42, 243, 0)',
    },
    backGradient3: {
      color1: 'rgba(40, 104, 243, 1)',
      color2: 'rgba(40, 104, 243, 0)',
    },
  };

  // private gradientColorsDark = {
  //   backGradient1: {
  //     color1: 'rgba(10, 54, 60, 1)',  // 더욱 어두운 파란색
  //     color2: 'rgba(10, 54, 60, 0)', 
  //   },
  //   backGradient2: {
  //     color1: 'rgba(27, 10, 60, 1)',  // 더욱 어두운 보라색
  //     color2: 'rgba(27, 10, 60, 0)', 
  //   },
  //   backGradient3: {
  //     color1: 'rgba(10, 26, 60, 1)',  // 더욱 어두운 파란색
  //     color2: 'rgba(10, 26, 60, 0)', 
  //   },
  // };

  // 색상 변경 함수 추가
  public changeColorsToWhite() {
    this.gradientColorsDark = {
      backGradient1: {
        color1: 'rgba(255, 255, 255, 1)',
        color2: 'rgba(255, 255, 255, 0)',
      },
      backGradient2: {
        color1: 'rgba(240, 240, 240, 1)',
        color2: 'rgba(240, 240, 240, 0)',
      },
      backGradient3: {
        color1: 'rgba(220, 220, 220, 1)',
        color2: 'rgba(220, 220, 220, 0)',
      },
    };

    // 변경된 색상을 SVG에 적용
    this.updategradientColorsDark();
  }

  // SVG 그라디언트 색상 업데이트 함수
  private updategradientColorsDark() {
    d3.select(`#backGradient1`)
      .selectAll('stop')
      .data(Object.values(this.gradientColorsDark.backGradient1))
      .attr('stop-color', (d) => d);

    d3.select(`#backGradient2`)
      .selectAll('stop')
      .data(Object.values(this.gradientColorsDark.backGradient2))
      .attr('stop-color', (d) => d);

    d3.select(`#backGradient3`)
      .selectAll('stop')
      .data(Object.values(this.gradientColorsDark.backGradient3))
      .attr('stop-color', (d) => d);
  }

  private createLayout() {
    this.createGradientRect(
      'backGradient1',
      this.gradientColorsDark.backGradient1.color1, // 전역 색상 사용
      this.gradientColorsDark.backGradient1.color2,
      '0.441602%',
      '34s',
      '13.744%',
      '1.18473%',
      {
        attributeName: 'x',
        dur: '20s',
        values: '25%;0%;25%',
        repeatCount: 'indefinite',
      },
      {
        attributeName: 'y',
        dur: '21s',
        values: '0%;25%;0%',
        repeatCount: 'indefinite',
      },
      {
        attributeName: 'transform',
        type: 'rotate',
        from: '0 50 50',
        to: '360 50 50',
        dur: '7s',
        repeatCount: 'indefinite',
      },
      'rotate(334.41 50 50)'
    );

    this.createGradientRect(
      'backGradient2',
      this.gradientColorsDark.backGradient2.color1, // 전역 색상 사용
      this.gradientColorsDark.backGradient2.color2,
      '2.68147%',
      '23.5s',
      '-2.17916%',
      '35.4267%',
      {
        attributeName: 'x',
        dur: '23s',
        values: '-25%;0%;-25%',
        repeatCount: 'indefinite',
      },
      {
        attributeName: 'y',
        dur: '24s',
        values: '0%;50%;0%',
        repeatCount: 'indefinite',
      },
      {
        attributeName: 'transform',
        type: 'rotate',
        from: '0 50 50',
        to: '360 50 50',
        dur: '12s',
        repeatCount: 'indefinite',
      },
      'rotate(255.072 50 50)'
    );

    this.createGradientRect(
      'backGradient3',
      this.gradientColorsDark.backGradient3.color1, // 전역 색상 사용
      this.gradientColorsDark.backGradient3.color2,
      '0.836536%',
      '21.5s',
      '9.00483%',
      '14.5733%',
      {
        attributeName: 'x',
        dur: '25s',
        values: '0%;25%;0%',
        repeatCount: 'indefinite',
      },
      {
        attributeName: 'y',
        dur: '12s',
        values: '0%;25%;0%',
        repeatCount: 'indefinite',
      },
      {
        attributeName: 'transform',
        type: 'rotate',
        from: '360 50 50',
        to: '0 50 50',
        dur: '9s',
        repeatCount: 'indefinite',
      },
      'rotate(139.903 50 50)'
    );
  }

  public createAnimate(node: any, value: IAnimateAttribute) {
    const item = node
      .append('animate')
      .attr('attributeName', value.attributeName)
      .attr('values', value.values)
      .attr('keyTimes', value.keyTimes)
      .attr('dur', value.dur)
      .attr('repeatCount', value.repeatCount)
      .attr('begin', value.begin);
    return item;
  }

  public createRadialGradient(node: any, value: any) {
    const defs = node.append('defs');
    const radialGradient = defs
      .append('radialGradient')
      .attr('id', value.id)
      .attr('cx', value.cx)
      .attr('cy', value.cy)
      .attr('fx', value.fx)
      .attr('fy', value.fy)
      .attr('r', value.r);
    return radialGradient;
  }

  private createGradientRect(
    id: string,
    color1: string,
    color2: string,
    fx: string,
    animationDur: string,
    x: string,
    y: string,
    animate1: IAnimateAttribute,
    animate2: IAnimateAttribute,
    animateTransform: IAnimateAttribute,
    rotate: string
  ) {
    const radialGradient = this.createRadialGradient(this.componentSVG, {
      id: id,
      cx: '50%',
      cy: '50%',
      fx: fx,
      fy: '50%',
      r: 0.5,
    });

    this.createAnimate(radialGradient, {
      attributeName: 'fx',
      dur: animationDur,
      values: '0%;3%;0%',
      repeatCount: 'indefinite',
    });

    this.createStop(radialGradient, {
      offset: '0%',
      stopColor: color1,
    });

    this.createStop(radialGradient, {
      offset: '100%',
      stopColor: color2,
    });

    const rect = this.createSVG(this.componentSVG, {
      type: DrawType.rect,
      x: x,
      y: y,
      width: '100%',
      height: '100%',
      fill: `url(#${id})`,
    });

    rect.attr('transform', rotate);
    this.createAnimate(rect, animate1);
    this.createAnimate(rect, animate2);
    this.createAnimateTransform(rect, animateTransform);
  }

  public createAnimateTransform(node: any, value: IAnimateAttribute) {
    const item = node
      .append('animateTransform')
      .attr('attributeName', value.attributeName)
      .attr('type', value.type)
      .attr('from', value.from)
      .attr('to', value.to)
      .attr('dur', value.dur)
      .attr('repeatCount', value.repeatCount);
    return item;
  }

  public createSVG(entity: any, values: any): any {
    const item = entity
      .append(values.type)
      .attr('id', values.id)
      .attr('class', values.class)
      .attr('xlink:href', values.href)
      .attr('d', values.geometry)
      .attr('x', values.x)
      .attr('x1', values.x1)
      .attr('x2', values.x2)
      .attr('y', values.y)
      .attr('y1', values.y1)
      .attr('y2', values.y2)
      .attr('cx', values.cx)
      .attr('cy', values.cy)
      .attr('r', values.r)
      .attr('rx', values.rx)
      .attr('ry', values.ry)
      .attr('width', values.width)
      .attr('height', values.height)
      .attr('fill', values.fill)
      .attr('filter', values.filter)
      .attr('clip-path', values.clipPath)
      .attr('stroke', values.stroke)
      .attr('stroke-dasharray', values.strokeDasharray)
      .attr('stroke-width', values.strokeWidth)
      .attr('stroke-linecap', values.strokeLinecap)
      .attr('stroke-linejoin', values.strokeLinejoin)
      .attr('stroke-miterlimit', values.strokeMiterlimit)
      .attr('font-size', values.fontSize)
      .attr('font-weight', values.fontWeight)
      .attr('opacity', values.opacity)
      .attr('fill-opacity', values.fillOpacity)
      .attr(
        'transform',
        `translate(${values.translate ? values.translate.x : 0}, ${
          values.translate ? values.translate.y : 0
        })`
      );
    if (values.type === DrawType.text) {
      item.text(values.text);
    }
    return item;
  }
}
